import { Box, Link, Stack, Typography } from '@mui/material';
import { useSessionActions } from '../hook/session';

export const ErrorPage = () => {
  const { logout } = useSessionActions();

  return (
    <Stack sx={{ justifyContent: 'center', p: 5, alignItems: 'center' }}>
      <Typography sx={{ mb: 2 }}>There was an error</Typography>
      <Box
        component={Link}
        onClick={logout}
        sx={(theme) => ({ color: `${theme.palette.primary.main} !important` })}
      >
        Go Back to Login
      </Box>
    </Stack>
  );
};
